

























import Vue from 'vue'
import MessageBus from '@/message-bus'

export default Vue.extend({
  props: {
    docId: Number,
  },
  data: () => ({
    isFetching: false,
    data: undefined as string | undefined,
  }),
  computed: {
    href(): string | undefined {
      if (!this.data) return
      return this.data as string
    },
  },
  created() {
    this.fetch()
  },
  destroyed() {
    this.revoke()
  },
  methods: {
    retry(): void {
      this.fetch()
    },
    revoke(): void {
      if (this.data) {
        URL.revokeObjectURL(this.data)
        this.data = undefined
      }
    },
    fetch(): void {
      this.isFetching = true
      this.revoke()

      this.$api.orders
        .downloadPdf(this.docId, false)
        .then(value => {
          this.data = value
        })
        .catch(err => MessageBus.error(err))
        .finally(() => {
          this.isFetching = false
        })
    },
  },
})
